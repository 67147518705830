import { Alert, Button, Form, Input, Typography } from 'antd';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import EmptyLayout from '../components/layout/EmptyLayout';
import SearchEngineOptimization from '../components/utility/seo';
import { useCustomer } from '../hooks/use-customer';

const { Text } = Typography;

interface formValue {
  email: string;
  password: string;
}

const SignIn = () => {
  const [form] = Form.useForm<formValue>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const {
    actions: { loginCustomer },
  } = useCustomer();

  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      const response = await loginCustomer(formValue);
      if (response.error) {
        // display error
        setError(true);
        return;
      }
      navigate('/');
    } catch (_) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmptyLayout title={'เข้าสู่ระบบ TSA'}>
      <SearchEngineOptimization title="Sign In" />
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={loading}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="email"
          label="อีเมล"
          required={false}
          rules={[{ required: true, message: 'กรุณากรอกอีเมล' }]}
        >
          <Input />
        </Form.Item>
        <div className="relative">
          <Form.Item
            name="password"
            label="รหัสผ่าน"
            required={false}
            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
          >
            <Input.Password />
          </Form.Item>
          <Link className="absolute right-0 top-0" to="/forgot-password">
            ลืมรหัสผ่าน
          </Link>
        </div>
        <Button type="primary" loading={loading} htmlType="submit" block>
          เข้าสู่ระบบ
        </Button>
      </Form>
      <div className="flex justify-center mt-[16px]">
        <Text className="mr-[5px]">ยังไม่มีบัญชี?</Text>
        <Link to="/sign-up">สมัครสมาชิก</Link>
      </div>
      {error && (
        <Alert
          className="!items-baseline !text-light-title !mt-4"
          message="ไม่สามารถนำคุณเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง หรือติดต่อฝ่าย IT ของเราหากคุณยังพบปัญหาอยู่"
          type="error"
          showIcon
        />
      )}
    </EmptyLayout>
  );
};

export default SignIn;
